import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {

   addContractVendor(payload) {
    let contract_type = window.localStorage.getItem("ContractType");
    return Api().post(`/v2/contract${contract_type}?organization_id=${organization_id()}`, payload);
  },
   addContractVendorNew(payload) {
     return Api().post(`/v2/contractVendor?organization_id=${organization_id()}`, payload);
   },

  editContractVendor(payload, id) {
    let contract_type = window.localStorage.getItem("ContractType");
    return Api().put(`/v2/contract${contract_type}/${id}?organization_id=${organization_id()}`, payload);
  },
   editContractVendorNew(payload, id) {

    return Api().put(`/v2/contractVendor/${id}?organization_id=${organization_id()}`, payload);
  },

  editContractSubCategory(payload, id) {
    return Api().put(`/v2/contractSubCategory/${id}?organization_id=${organization_id()}`, payload);
  },

  getContractVendor(filters, sort) {
    let contract_type = window.localStorage.getItem("ContractType");
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/contract${contract_type}?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/contract${contract_type}?organization_id=${organization_id()}&page_no=${filters.page}`);
    } else
      return Api().get(`/v2/contract${contract_type}?organization_id=${organization_id()}`);
  },
    getContractVendorNew(filters, sort) {
      let contract_type = window.localStorage.getItem("ContractType");
      if (filters && filters.hasOwnProperty('archive') && filters.archive) {
        return Api().get(`/v2/contractVendor?archive=1&organization_id=${organization_id()}`);
      } else if (filters && filters.hasOwnProperty('page')) {
        return Api().get(`/v2/contractVendor?organization_id=${organization_id()}&page_no=${filters.page}`);
      } else
        return Api().get(`/v2/contractVendor?organization_id=${organization_id()}`);
    },

  getContractProjects(filters, sort) {
    let contract_type = window.localStorage.getItem("ContractType");
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/contractProject?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      // return Api().get(`/v2/contractProject/page/${filters.page}?organization_id=${organization_id}`);
      return Api().get(`/v2/contractProject?organization_id=${organization_id()}&page_no=${filters.page}`);
    } else
      return Api().get(`/v2/contractProject?organization_id=${organization_id()}`);
  },

  addContractProject(payload) {
    return Api().post(`/v2/contractProject?organization_id=${organization_id()}`, payload);
  },

  getSingleContractProject(payload) {
    return Api().get(`/v2/contractProject?organization_id=${organization_id()}`, payload);
  },

  getContractVendorListMou(filters) {

    let contract_type = filters.contractType;
    let url = `/v2/contract${contract_type}?organization_id=${organization_id()}`;
    if (contract_type == 'Agreement') {
      url += "&contractAll=1";

    }
    return Api().get(url);
  },

  getGrantChart(formType) {
    let url = `/v2/formFla/getGraphData/${
      formType.id
      }?organization_id=${organization_id()}`
    Object.keys(formType).map(x => {
      if (x != 'id') {
        url += "&" + x + "=" + formType[x]
      }
    });
    return Api().get(url);
  },

  getContractSubCategory(filters, sort) {
    let contract_type = window.localStorage.getItem("ContractType");
    if (filters && filters.hasOwnProperty('archive') && filters.archive) {
      return Api().get(`/v2/contractSubCategory/getDataByParentCategory/${filters.category_id}?archive=1&organization_id=${organization_id()}`);
    } else if (filters && filters.hasOwnProperty('page')) {
      return Api().get(`/v2/contractSubCategory/getDataByParentCategory/${filters.category_id}?organization_id=${organization_id()}`);
    } else
      return Api().get(`/v2/contractSubCategory/getDataByParentCategory/${filters.category_id}?organization_id=${organization_id()}`);
  },

  addContractSubCategory(payload) {
    return Api().post(`/v2/contractSubCategory?organization_id=${organization_id()}`, payload);
  },

  getAllContractData() {
    return Api().get(`/v2/contractVendor/getAllContractData?organization_id=${organization_id()}`);
  },

  getAllContractDataLevel() {
    return Api().get(`/v2/contractVendor/getContractUserLevels?organization_id=${organization_id()}`);
  },

  // editAllContractData(id) {
  //     return Api().post(`/v2/contractVendor/getAllContractData?organization_id=${organization_id}`);
  // },

  getAllMouFormList(id, search, project_id,page_no) {
    let url = `/v2/formMou?type=${id}&organization_id=${organization_id()}`;
    if (search != '') {
      url += "&search=" + search;
    }
    if (project_id != '') {
      url += "&project_id=" + project_id;
    }
    if (page_no != '') {
      url += "&page_no=" + page_no;
    } else {
       url += "&page_no=1";
    }
    return Api().get(url);
  },

  getAllFlaFormList(id, search, project_id, page_no) {
    let url = `/v2/formFla?type=${id}&organization_id=${organization_id()}`;
    if (search != '') {
      url += "&search=" + search;
    }
    if (project_id != '') {
      url += "&project_id=" + project_id;
    }
     if (page_no != '') {
       url += "&page_no=" + page_no;
     } else {
       url += "&page_no=1";
     }
    return Api().get(url);
    // return Api().get(`/v2/formFla?type=${id}&organization_id=${organization_id}`);
  },

  getSignleMouFormDetails(id) {
    console.log('getSignleMouFormDetails :>> ', id);
    return Api().get(`/v2/formMou?mou_id=${id}&organization_id=${organization_id()}`);
  },

  addMouForm(payload) {
    return Api().post(`/v2/formMou?organization_id=${organization_id()}`, payload);
  },

  getComments(id) {
    return Api().get(`/v2/formMouComments?mou_id=${id}&organization_id=${organization_id()}`);
  },

  addMouFormCommet(payload) {
    return Api().post(`/v2/formMouComments?organization_id=${organization_id()}`, payload);
  },

  addMouFormReplyCommet(payload) {
    return Api().post(`/v2/formMouComments?organization_id=${organization_id()}&isReply=1`, payload);
  },


  // FLA FORM


  getSignleFlaFormDetails(id) {
    console.log('getSignleFlaFormDetails :>> ', id);
    return Api().get(`/v2/formFla?fla_id=${id}&organization_id=${organization_id()}`);
  },

  addFlaForm(payload) {
    return Api().post(`/v2/formFla?organization_id=${organization_id()}`, payload);
  },
  getFlaComments(id) {
    return Api().get(`/v2/formFlaComments?fla_id=${id}&organization_id=${organization_id()}`);
  },

  addFlaFormComments(payload) {
    return Api().post(`/v2/formFlaComments?organization_id=${organization_id()}`, payload);
  },

  addFlaFormReplyComments(payload) {
    return Api().post(`/v2/formFlaComments?organization_id=${organization_id()}&isReply=1`, payload);
  },

  getStatusData(payload) {
    let url = `/v2/formFla/getStatusData/${payload.id}?organization_id=${organization_id()}`
    Object.keys(payload).map(x => {
      if (x != 'id') {
        url += "&" + x + "=" + payload[x]
      }
    });
    console.log(url);
    return Api().get(url);
  },

  getReasonMou(payload) {
    let url = `/v2/formMou/getReasons?organization_id=${organization_id()}`
    Object.keys(payload).map(x => {

      url += "&" + x + "=" + payload[x]

    });
    // console.log(url);
    return Api().get(url);

  },
  getReasonFla(payload) {
    let url = `/v2/formFla/getReasons?organization_id=${organization_id()}`
    Object.keys(payload).map(x => {

      url += "&" + x + "=" + payload[x]

    });
    // console.log(url);
    return Api().get(url);

  },
  getDraft(payload) {
    let url = `/v2/formDraft?organization_id=${organization_id()}`
    if (Object.keys(payload).length) {
      Object.keys(payload).map(x => {

        url += "&" + x + "=" + payload[x]

      });
    }

    // console.log(url);
    return Api().get(url);

  },
  addDraft(payload) {
    return Api().post(`/v2/formDraft?organization_id=${organization_id()}`, payload);
    //https: //dev1-api.gokozo.com/v2/formDraft?organization_id=8


  },
  moveToBudget(payload) {
    return Api().post(`/v2/formMou/linkToBudget?organization_id=${organization_id()}`, payload);
    //https: //dev1-api.gokozo.com/v2/formDraft?organization_id=8


  },
  moveToBudgetFLA(payload) {
    return Api().post(`/v2/formFla/linkToBudget?organization_id=${organization_id()}`, payload);
    //https: //dev1-api.gokozo.com/v2/formDraft?organization_id=8


  },

};
